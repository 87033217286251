import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ats_score: 0,
  resumeReport: [],
  fieldsToAdd: [],
  Linkedin_details: {
    Title: "",
    link: "",
    snippet: ""
  }
};

export const recruiterSlice = createSlice({
  name: "recruiters",
  initialState,
  reducers: {
    // reucers
    updateResumeRecruiters: (state, action) => {
      state.ats_score = action.payload.ats_score;
      state.resumeReport = action.payload.resumeReport;
      state.fieldsToAdd = action.payload.fieldsToAdd;
      state.Linkedin_details = action.payload.Linkedin_details;
    },
    resetResumeData: (state) => {
      return initialState;
    }
  }
});

export const { updateResumeRecruiters, resetResumeData } =
  recruiterSlice.actions;

export default recruiterSlice.reducer;
