import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Total: 0,
  Text: "",
  scores: [],
  report: {
    Basic: [],
    Professional_Background: [],
    Skills: [],
    Formats: [],
    Accomplishments: []
  }
};

export const ailinkedinSlice = createSlice({
  name: "ailinkedin",
  initialState,
  reducers: {
    updateAilinkedin: (state, action) => {
      state.scores = action.payload.scores;
      state.report = action.payload.report;
      state.Total = action.payload.Total;
      state.Text = action.payload.Text;
    },

    resetAilinkedin: (state) => {
      return initialState;
    }
  }
});

export const { updateAilinkedin, resetAilinkedin } = ailinkedinSlice.actions;

export default ailinkedinSlice.reducer;
