import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const fbPagesSlice = createSlice({
  name: "fbPages",
  initialState,
  reducers: {
    updateFbPages: (state, action) => {
      const { id, pages } = action.payload;
      return {
        ...state,
        [id]: pages
      };
    }
  }
});

export const { updateFbPages } = fbPagesSlice.actions;

export default fbPagesSlice.reducer;
