import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();
  const scrollToTop = () => {
    if (!window.location.href.includes("#")) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const excludedRoutesForScrolling = [
      "/products/ats-resume-checker",
      "/products/ai-linkedin-optimization",
      "/products/resume-jd-syncer",
      "/products/resume-data-scout",
      "/products/job-description-scanner",
      "/products/top-talent-selector"
    ];
    if (!excludedRoutesForScrolling.includes(location.pathname)) {
      scrollToTop();
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("load", scrollToTop);
    window.addEventListener("popstate", scrollToTop);

    return () => {
      window.removeEventListener("load", scrollToTop);
      window.removeEventListener("popstate", scrollToTop);
    };
  }, []);

  return null;
};

export default ScrollToTop;
