import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Experience: "",
  Location: "",
  Objective: { Old: "", New: "" },
  Project: { Old: "", New: "" },
  "Hard Skills": { Old: "", New: "" },
  "Soft Skills": { Old: "", New: "" }
};

const resumeSlice = createSlice({
  name: "resumejdSyncer",
  initialState,
  reducers: {
    updateResumejdSyncer: (state, action) => {
      state.Experience = action.payload.Experience;
      state.Location = action.payload.Location;
      state.Objective = action.payload.Objective;
      state["Hard Skills"] = action.payload["Hard Skills"];
      state["Soft Skills"] = action.payload["Soft Skills"];
    },
    resetResumejdSyncer: (state) => {
      return initialState;
    }
  }
});

export const { updateResumejdSyncer, resetResumejdSyncer } =
  resumeSlice.actions;

export default resumeSlice.reducer;
