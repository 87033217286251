import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facebook: {},
  instagram: {},
  linkedin: {}
};

export const smmPostSlice = createSlice({
  name: "smmPosts",
  initialState,
  reducers: {
    updateSmmPosts: (state, action) => {
      const { id, platform, posts } = action.payload;
      return {
        ...state,
        [platform]: {
          ...state[platform],
          [id]: [
            ...(state[platform] ? state[platform][id] || [] : []),
            ...posts
          ]
        }
      };
    },
    deleteFBPost: (state, action) => {
      const { userId, pagePostId } = action.payload;
      return {
        ...state,
        facebook: {
          ...state["facebook"],
          [userId]: [
            ...state["facebook"][userId].filter(
              (facebookPost) => facebookPost.post_id !== pagePostId
            )
          ]
        }
      };
    },
    deleteLinkedinPost: (state, action) => {
      const { postUrn, userId } = action.payload;
      return {
        ...state,
        linkedin: {
          ...state["linkedin"],
          [userId]: [
            ...state["linkedin"][userId].filter(
              (linkedinPost) => linkedinPost.postId !== postUrn
            )
          ]
        }
      };
    }
  }
});

export const { updateSmmPosts, deleteFBPost, deleteLinkedinPost } =
  smmPostSlice.actions;

export default smmPostSlice.reducer;
