import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  Basic: {},
  Experience: [],
  Education: [],
  Projects: [],
  Skills: [],
  Awards: [],
  Certificates: []
};

// Create a slice
// Create a slice named dataScoutSlice
export const dataScoutSlice = createSlice({
  name: "dataScout",
  initialState,
  reducers: {
    updateDataScout: (state, action) => {
      return { ...action.payload };
    },
    resetDataScout: () => initialState
  }
});

// Export actions specific to dataScoutSlice
export const { updateDataScout, resetDataScout } = dataScoutSlice.actions;

// Export the reducer
export default dataScoutSlice.reducer;
