import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Total: 0,
  Text: "",
  scores: [],
  report: {
    Basic: [],
    Professional_Background: [],
    Skills: [],
    Formats: [],
    Accomplishments: []
  }
};

export const jobDescriptionSlice = createSlice({
  name: "jobDescription",
  initialState,
  reducers: {
    updateJobDescriptionData: (state, action) => {
      state.scores = action.payload.scores;
      state.report = action.payload.report;
      state.Total = action.payload.Total;
      state.Text = action.payload.Text;
    },

    resetJobDescriptionData: (state) => {
      return initialState;
    }
  }
});

export const { updateJobDescriptionData, resetJobDescriptionData } =
  jobDescriptionSlice.actions;

export default jobDescriptionSlice.reducer;
