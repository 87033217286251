import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  linkedin: {},
  facebook: {},
  instagram: {}
};

export const socialMediaDetailSlice = createSlice({
  name: "socialMediaDetails",
  initialState,
  reducers: {
    updateSocialMediaDetails: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { updateSocialMediaDetails } = socialMediaDetailSlice.actions;

export default socialMediaDetailSlice.reducer;
