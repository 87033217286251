import { useRef } from "react";
import { useInView, motion as m } from "framer-motion";

const FadeDown = ({ children, delay = 0, left = "auto" }) => {
  const containerRef = useRef(null);
  const containerInView = useInView(containerRef, { amount: 0.2, once: true });

  return (
    <m.span
      ref={containerRef}
      style={{
        opacity: containerInView ? 0 : 1,
        transform: containerInView ? "translateY(0)" : "translateY(-24px)",
        transitionDuration: "0.5s",
        transitionDelay: containerInView ? `${delay}s` : "0s",
        height: "fit-content",
        display: "block",
        position: "absolute",
        top: "70px",
        left: left
      }}
    >
      {children}
    </m.span>
  );
};

export default FadeDown;
