// topResumeSlice.ts
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topResumes: []
};

export const topResumeSlice = createSlice({
  name: "topResumes",
  initialState,
  reducers: {
    addResume: (state, action) => {
      state.topResumes.push(...action.payload);
    },
    removeResume: (state, action) => {
      state.topResumes.splice(action.payload, 1);
    },
    removeAllResumes: () => initialState
  }
});

export const { addResume, removeResume, removeAllResumes } =
  topResumeSlice.actions;

export default topResumeSlice.reducer;
