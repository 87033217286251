import { useRef } from "react";
import { useInView, motion as m } from "framer-motion";

const FadeUp = ({
  children,
  delay = 0,
  duration = 0.85,
  position,
  left = "auto"
}) => {
  const containerRef = useRef(null);
  const containerInView = useInView(containerRef, { threshold: 0.2 });

  return (
    <m.span
      ref={containerRef}
      style={{
        opacity: containerInView ? 1 : 0,
        transform: containerInView ? "translateY(0)" : "translateY(24px)",
        transition: `opacity ${duration}s, transform ${duration}s`,
        transitionDelay: containerInView ? `${delay}s` : "0s",
        height: "fit-content",
        display: "block",
        position: position ? "absolute" : "static",
        left: left
      }}
    >
      {children}
    </m.span>
  );
};

export default FadeUp;
