import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import candidateReducer from "./atsresume";
import recruiterReducer from "./recruiterSlice";
import jobDescriptionReducer from "./jobDescriptionSlice";
import topResumeReducer from "./topResumeSlice"; // Import the topResumeSlice

// Define rootReducer with all slices combined
import smmPostsReducer from "./smmPostsSlice";
import socialMediaDetailsReducer from "./socialMediaSlice";
import fbPagesReducer from "./fbPagesSlice";
import linkedinOrgsReducer from "./linkedinOrgsSlice";
import ailinkedinReducer from "./ailinkedin";
import resumejdsyncerReducer from "./resumejdsyncer";
import dataScoutReducer from "./resumeDataScoutSlice";
const rootReducer = combineReducers({
  candidate: candidateReducer,
  recruiter: recruiterReducer,
  ailinkedin: ailinkedinReducer,
  topResume: topResumeReducer, // Include the topResume slice
  jobDescription: jobDescriptionReducer,
  resumejdsyncer: resumejdsyncerReducer,
  dataScout: dataScoutReducer,
  smmPosts: smmPostsReducer,
  socialMediaDetails: socialMediaDetailsReducer,
  fbPages: fbPagesReducer,
  linkedinOrgs: linkedinOrgsReducer
});

// Configure redux-persist
const persistConfig = {
  key: "root",
  storage
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

// Create the Redux persistor
export const persistor = persistStore(store);
