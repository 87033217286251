import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const linkedinOrgsSlice = createSlice({
  name: "linkedinOrgs",
  initialState,
  reducers: {
    updateLinkedinOrgs: (state, action) => {
      const { userId, linkedinOrgs } = action.payload;
      return {
        ...state,
        [userId]: linkedinOrgs
      };
    }
  }
});

export const { updateLinkedinOrgs } = linkedinOrgsSlice.actions;

export default linkedinOrgsSlice.reducer;
