import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Course: [],
  Total: 0,
  Text: "",
  scores: [],
  report: {
    Basic: [],
    Professional_Background: [],
    Skills: [],
    Formats: [],
    Accomplishments: []
  }
};

export const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    updateResumeCandidates: (state, action) => {
      state.scores = action.payload.scores;
      state.report = action.payload.report;
      state.Course = action.payload.Course;
      state.Total = action.payload.Total;
      state.Text = action.payload.Text;
      state.Skills = action.payload.Skills;
    },

    resetResumeData: (state) => {
      return initialState;
    }
  }
});

export const { updateResumeCandidates, resetResumeData } =
  candidateSlice.actions;

export default candidateSlice.reducer;
