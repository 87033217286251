import { useAnalyticsEventTracker } from "components/Analytics/index";
import React from "react";
import { Tag } from "antd";

export default function ProductPopup({
  popupListData,
  navigate,
  closeSideBar,
  setDropdown,
  title
}) {
  const gaEventTracker = useAnalyticsEventTracker("Products");

  return (
    <div className="headerProductPopup">
      <div
        className="headerProductPopupContainer"
        style={{ gridTemplateColumns: title !== "product" && "1fr" }}
      >
        {Object.keys(popupListData).map((key) => (
          <div key={key}>
            <div style={{ display: "flex" }}>
              {title === "product" && (
                <>
                  <div>
                    <h2>{popupListData[key].productTitle}</h2>
                  </div>

                  <div className="headerProductPopupTagContainer">
                    <Tag color={popupListData[key].tagClr}>
                      {popupListData[key].tagContent}
                    </Tag>
                  </div>
                </>
              )}
            </div>
            <ul>
              {popupListData[key].data.map((data, i) => (
                <li
                  className="headerProductPopupList"
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // eslint-disable-next-line
                    if (typeof window !== "undefined") {
                      let url = data.location;

                      // Ensure 'data.location' starts with 'http://' or 'https://'
                      if (!/^https?:\/\//i.test(url)) {
                        url = window.location.origin + url;
                      }

                      if (data.service === "Blogs") {
                        window.open(url, "_blank", "noopener,noreferrer");
                      } else {
                        window.location.href = url;
                      }
                    }

                    closeSideBar();
                    setDropdown(false);
                    gaEventTracker("Click", data.service);
                  }}
                >
                  {data.service === "Start Ups" ? (
                    <img
                      src={data.img}
                      style={{ width: "45px", height: "45px" }}
                    />
                  ) : data.img ? (
                    <div>{data.img}</div>
                  ) : (
                    <img
                      src={`${data.imgLink}`}
                      style={{ width: "45px", height: "45px" }}
                    />
                  )}
                  <div className="popupList" style={{ width: "200px" }}>
                    <span>{data.service}</span>
                    <p>{data.feature}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
